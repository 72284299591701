@font-face {
  font-weight: 500;
  font-family: Lora;
  font-style: normal;
  src: url('@/assets/fonts/Lora-Medium.woff2') format('woff2'), url('@/assets/fonts/Lora-Medium.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-weight: 400;
  font-family: Lora;
  font-style: normal;
  src: url('@/assets/fonts/Lora-Regular.woff2') format('woff2'), url('@/assets/fonts/Lora-Regular.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-weight: 700;
  font-family: 'Noto Sans';
  font-style: normal;
  src: url('@/assets/fonts/NotoSans-Bold.woff2') format('woff2'),
    url('@/assets/fonts/NotoSans-Bold.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-weight: 400;
  font-family: 'Noto Sans';
  font-style: normal;
  src: url('@/assets/fonts/NotoSans-Regular.woff2') format('woff2'),
    url('@/assets/fonts/NotoSans-Regular.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-weight: 300;
  font-family: 'Test Tiempos Headline';
  font-style: normal;
  src: url('@/assets/fonts/TiemposHeadline-Light.woff2') format('woff2'),
    url('@/assets/fonts/TiemposHeadline-Light.woff') format('woff');
  font-display: swap;
}
