@use '../utils/mixins' as mix;
@use '../utils/variables' as *;
@use '../utils/functions' as *;

.btn {
  display: inline-flex;
  justify-content: center;
  width: fit-content;
  padding: 13px 57px;
  color: #fff;
  font-weight: 700;
  font-size: 15px;
  font-family: 'Noto Sans', sans-serif;
  line-height: 1.4;
  text-align: center;
  text-transform: uppercase;
  border-radius: 100px;

  @media (max-width: $sm) {
    width: 100%;
  }

  &_orange {
    background: #c17441;
  }

  &_brown {
    background: #4d4039;
  }

  &_default {
    color:$text-dark;
    font-weight: 400;
    font-size: 18px;
    text-transform: none;
    border: 1px solid rgba(117, 84, 65, 20%);
    border-radius: 10000px;
  }

  &_white {
    color:$text-dark;
    background: $white;
  }
}
