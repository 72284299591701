@use '../utils/mixins' as mix;
@use '../utils/variables' as vars;
@use '../utils/functions' as fn;

.app {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  color: vars.$text-dark;
  font-weight: 400;
  font-size: 20px;
  font-family: 'Noto Sans', sans-serif;
  line-height: 1.4;
  background: vars.$white;
}

.container {
  width: 100%;
  max-width: calc(vars.$container + (vars.$padding * 2));
  margin: 0 auto;
  padding-right: vars.$padding;
  padding-left: vars.$padding;
}

.section {
  padding: fn.cmin(40, 80) 0;

  &__title {
    font-weight: 400;
    font-size: fn.cmin(28, 36);
    font-family: Lora, sans-serif;
    line-height: fn.cmin(36, 47);

    @media (max-width: vars.$lg) {
      font-weight: 300;
    }

    &_small {
      font-family: Lora, sans-serif;
      line-height: fn.cmin(31, 47);
    }
  }
}


.is-hidden {
  display: none;
}