@use '../utils/mixins' as mix;
@use '../utils/variables' as vars;
@use '../utils/functions' as fn;

.benefits {
  background: linear-gradient(180deg, #f5f2eb 0%, #d1e9d0 100%);

  &__container {
    display: flex;
    flex-direction: column;
    gap: fn.cmin(32, 60);
    align-items: center;
    text-align: center;
  }

  &__list {
    @media (max-width: vars.$md) {
      display: none;
    }
  }

  &__slider {
    &-block {
      width: 100%;

      @media (min-width: vars.$min-md) {
        display: none;
      }
    }

    &-progress {
      margin-top: 20px;
    }
  }

  &__item {
    position: relative;

    &::before {
      position: absolute;
      width: 100%;
      height: 100%;
      background: linear-gradient(180deg, rgba(238, 233, 221, 0%) 37.44%, #eee9dd 88.75%);
      border-radius: 200px 200px 12px 12px;
      content: '';
      inset: 0;
    }
  }

  &__img {
    &-wrap,
    & {
      width: 100%;
    }

    @include mix.ratio(300, 400);

    border-radius: 200px 200px 12px 12px;
  }

  &__desc {
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 2;
    padding: 26px;
    font-weight: 500;
    font-size: 20px;
    font-family: Lora, sans-serif;
    line-height: 28px;
  }
}
