@use '../utils/mixins' as mix;
@use '../utils/variables' as vars;
@use '../utils/functions' as fn;

.memorable {
  background: linear-gradient(180deg, #d2ead1 0%, #f5f2eb 100%);

  @media (max-width: vars.$lg) {
    text-align: center;
  }

  &__row {
    row-gap: 32px;
  }

  &__text-block {
    display: flex;
    flex-direction: column;
    gap: fn.cmin(24, 40);
    justify-content: center;
    height: 100%;

    @media (max-width: vars.$lg) {
      align-items: center;
    }
  }

  &__title-desc {
    display: flex;
    flex-direction: column;
    gap: fn.cmin(20, 32);
  }

  &__desc {
    font-weight: 400;
    font-size: 18px;
    font-family: 'Noto Sans', sans-serif;
    line-height: 26px;
  }

  &__slider {
    &-progress {
      margin-top: 24px;

      @media (max-width: vars.$lg) {
        display: none;
      }
    }

    &-btn {
      &-block {
        position: absolute;
        bottom: 24px;
        left: 50%;
        z-index: 7;
        display: flex;
        gap: 12px;
        transform: translateX(-50%);

        @media (max-width: vars.$lg) {
          display: none;
        }
      }

      position: relative;
      padding: 15px 33px;
      background: linear-gradient(113.4deg, rgba(234, 161, 106, 98.4%) 8.38%, #bf713f 89.62%);
      border-radius: 30px;

      &_next {
        svg {
          transform: rotate(180deg);
        }
      }

      &::after {
        position: absolute;
        margin: 9px;
        border: 1px solid vars.$white;
        border-radius: 21px;
        content: '';
        inset: 0;
      }

      svg {
        width: 24px;
        height: 24px;
        color: vars.$white;
      }
    }
  }

  &__item {
    position: relative;

    &::before {
      position: absolute;
      background: linear-gradient(180deg, rgba(0, 0, 0, 0%) 48.39%, rgba(0, 0, 0, 40%) 100%);
      content: '';
      inset: 0;

      @media (max-width: vars.$lg) {
        border-radius: 1000px;
      }
    }
  }

  &__img {
    &-wrap,
    & {
      width: 100%;
    }

    border-radius: 400px 400px 24px 24px;

    @include mix.ratio(620, 570);

    @media (max-width: vars.$lg) {
      border-radius: 1000px;

      @include mix.ratio(269, 350);
    }
  }
}
