.visually-hidden {
  position: absolute !important;
  width: 0 !important;
  height: 0 !important;
  overflow: hidden !important;
  white-space: nowrap !important;
  clip: rect(0 0 0 0) !important;
  clip-path: inset(50%) !important;
}

main,
footer {
  overflow-x: hidden;
}

html {
  box-sizing: border-box;
  scroll-behavior: smooth;
  margin: 0;
  padding: 0;
}

body *,
*::after,
*::before {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: inherit;
  font: inherit;
  -webkit-tap-highlight-color: transparent;
}

ul,
ol {
  list-style: none;
}

a,
img,
picture,
svg,
strong {
  display: inline-block;
}

a {
  text-decoration: none;
}

svg {
  flex: none;
  max-width: 100%;
  max-height: 100%;
  font-size: 0;
  vertical-align: middle;
}

input,
button,
textarea,
select {
  padding: 0;
  background: none;
  border: none;
  outline: none;
  appearance: none;
}

button {
  text-align: left;
  cursor: pointer;
}

textarea {
  resize: none;
}

img,
video {
  max-width: 100%;
  height: auto;
  max-height: 100%;
  object-fit: cover;
  vertical-align: middle;
}

table {
  border: none;
  border-collapse: collapse;
  border-spacing: 0;
}

input[type='password'] {
  &::-ms-reveal {
    display: none;
  }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  appearance: none;
}

input[type='text']::-ms-clear {
  display: none;
  width: 0;
  height: 0;
}

input[type='text']::-ms-reveal {
  display: none;
  width: 0;
  height: 0;
}

input[type='search']::-webkit-search-decoration,
input[type='search']::-webkit-search-cancel-button,
input[type='search']::-webkit-search-results-button,
input[type='search']::-webkit-search-results-decoration {
  display: none;
}
